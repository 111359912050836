<template>
  <div style="height: 500px">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col style="max-width: 800px">
          <v-card>
            <div
              class="pt-7 d-flex flex-column justify-space-between align-center"
            >
              <v-img max-width="200" src="img/dl.png"></v-img>
            </div>
            <v-card-title class="text-h5">
              Do you want to change to another project?
            </v-card-title>

            <v-card-text>
              You are currently working in the '<span class="font-weight-bold" v-html="result.c_domainName"></span>'
              project<span v-if="typeof result.newOrganName != 'undefined'">, in Organisation '<span class="font-weight-bold" v-html="result.c_orgName"></span>'</span
              >. In order to view the test from the link, you must change to the
              '<span class="font-weight-bold" v-html="result.projectName"></span>' project<span
                v-if="typeof result.newOrganName != 'undefined'"
                >, in Organisation '<span class="font-weight-bold" v-html="result.newOrganName"></span>'</span
              >.
            </v-card-text>
            <v-card-actions>
              <div class="mr-2 d-inline-block">
                <primaryButton
                  label="Change"
                  @click="clickAndSwitchProject(result.projectId)"
                  type="button"
                  color="save"
                  id="switch_Button"
                  data-type="switch_Button"
                ></primaryButton>
              </div>
              <div class="mr-2 d-inline-block">
                <router-link to="?f=testing&f2=configureAndRunTests">
                  <primaryButton
                    label="Stay"
                    type="button"
                    color="primary"
                    id="stay_Button"
                    data-type="stay_Button"
                  ></primaryButton>
                </router-link>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  components: { primaryButton },

  props: ["result"],
  data: function () {
    return {};
  },
  methods: {
    clickAndSwitchProject(id) {
      this.frameworkAxiosRequest({
        method: 'POST',
        url: 'serve.php?f=administration&f2=projectAdministration',
        dataType: 'json',
        data: {
          project: id,
          organisations: this.result.organisations,
          requestType: 'ajax',
          function: 'switchProjectAjax',
        },
      })
          .then(function () {
          location.reload();
        })
        .catch(function (response) {
          console.log(response);
        });
    },
  },
};
</script>